import axios from 'axios';
import swal from "sweetalert";
import request from './request';
import {
    loginConfirmedAction,
    Logout,
} from '../store/actions/AuthActions';


// FOR LARAVEL -> POSTGREsql:

// export function signUp(data) {
//     return request('auth/register', {
//         data,
//         method: 'POST'
//     });
// }

// FOR PHP -> JSON:

export function signUp(data) { 
    return axios.post('https://test1.jobsi.app/registerScript.php', data);
}
 
// FOR LARAVEL -> POSTGREsql:

// export function login(login, password) {
//     return request('auth/login', {
//         data: {
//             login,
//             password,
//         },
//         method: 'POST',
//     });
// }

// FOR PHP -> JSON:

export function login(login, password) {
    return axios.post('https://test1.jobsi.app/isRegistered.php', {
        login,
        password,
    })
    .then(response => {
        // Предполагаем, что сервер возвращает успешный результат с полем success
        if (response.data.success) {
            return {
                success: true,
                message: 'Login successful',
            };
        } else {
            return {
                success: false,
                message: 'Invalid login or password',
            };
        }
    })
    .catch(error => {
        // Обработка ошибок, если они возникнут при выполнении запроса
        console.error('Error during login:', error);
        return {
            success: false,
            message: 'An error occurred during login',
        };
    });
}


export function formatError(errorResponse) {
    switch (errorResponse.error.message) {
        case 'EMAIL_EXISTS':
            swal("Oops", "Email already exists", "error");
            break;
        case 'EMAIL_NOT_FOUND':
            swal("Oops", "Email not found", "error", { button: "Try Again!", });
            break;
        case 'INVALID_PASSWORD':
            swal("Oops", "Invalid Password", "error", { button: "Try Again!", });
            break;
        case 'USER_DISABLED':
            return 'User Disabled';

        default:
            return '';
    }
}

export function saveTokenInLocalStorage(bearerToken) {
    localStorage.setItem('bearerToken', JSON.stringify(bearerToken));
}

export function runLogoutTimer(dispatch, timer, navigate) {
    setTimeout(() => {
        dispatch(Logout(navigate));
    }, timer);
}

export function checkAutoLogin(dispatch, navigate) {
    const bearerToken = localStorage.getItem('bearerToken');
    if (!bearerToken) {
        dispatch(Logout(navigate));
        return;
    }

    dispatch(loginConfirmedAction(bearerToken));
}

export function isLogin() {
    const tokenDetailsString = localStorage.getItem('bearerToken');

    if (tokenDetailsString) {
        return true;
    } else {
        return false;
    }
}