import React, { Fragment, useEffect, useState, useRef } from "react";
import { useSelector } from 'react-redux';
import { Tab, Nav } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import request from '../../../../services/request';
import axios from 'axios';
import EditEmployeeOffcanvas from '../../../constant/EditEmployeeOffcanvas';

// import styles
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';

// import JSX Components
import PersonalInfo from '../../EmployeeProfile/PersonalInfo';
import ProfileHeader from '../../EmployeeProfile/ProfileHeader';
import MainPagetitle from '../../../layouts/MainPagetitle';
import EmployeeEvents from '../../EmployeeProfile/EmployeeEvents';

const AppProfile = (props) => {
	const firstCompany = useSelector(state => (state.company && state.company.firstCompany) || '');
	const [dataUpdateTrigger2, setDataUpdateTrigger2] = useState(false);
	const [actualTovarna, setTovarna] = useState(''); 
	const [actualUbytovna, setUbytovna] = useState('');
	const [actualRoom, setRoom] = useState('');
	const [actualRoomName, setRoomName] = useState('');
	const params = useParams();
	const navigate = useNavigate();
	const [employee, setEmployee] = useState();

	useEffect(() => {
		const email = params.email;
		if (!email) {
			navigate('/employee');
			return;
		}

		fetchEmployee(email);
	}, []); 

	useEffect(() => {

		fetchDataTovarny();
	}, [dataUpdateTrigger2]); 

	useEffect(() => {

		fetchDataTovarny();
	}, []); 

	const fetchDataTovarny = async () => {
    
		try {
		  const response = await fetch(`/${firstCompany}/file.json?${Date.now()}`);
		  const data = await response.json();
		  console.log('foundItem data', data);
		  console.log('foundItem params.email', params.email);
		  const foundItem = data.find(item => item.email === params.email);
		  console.log('foundItem', foundItem);
		  console.log('foundItem', foundItem.email);
		  setTovarna(foundItem.stredisko);
		  setUbytovna(foundItem.ubytovna);
		  setRoom(foundItem.roomID);
		  setRoomName(foundItem.roomName);
		} catch (error) {
		  console.error('Ошибка при загрузке данных:', error);
		}
	  };
////////////



	const editEmploye = useRef();
	const [selectedEmployee, setSelectedEmployee] = useState(null);

	const handleEdit = (employee) => {
		setSelectedEmployee(employee); 
		editEmploye.current.showEditEmployeModal();
	  };



///////////


 
	const fetchEmployee = async (email) => {
		console.log('data1233 1');
		console.log('email', email);
		console.log('firstCompany', firstCompany);

		try {
			const response = await axios.get(`https://test1.jobsi.app/employee/get.php`, { 
			  params: {
				email: email,
				firstCompany: firstCompany,
			  },
			  headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
			  },
			});
		  
			const data = response.data;
			console.log('response', response);
			console.log('data1233', data);
			if (data) {
				console.log('appProfile -- data.employee ', data.employee);
				console.log('appProfile -- data.employee.email ', data.employee.email);
				setEmployee(data.employee);
				return;
			}

			console.error(data.message);
		} catch (error) {
			console.error('Ошибка при загрузке данных:', error);
		}
	};

	if (!employee) {
		return (
			<Fragment>

			</Fragment>
		);
	}

	return (
		<>
			<MainPagetitle mainTitle="Employee profile" pageTitle="Employee profile" parentTitle="Pracovníky" />
			<Fragment>
				<div className="container-fluid">
					<ProfileHeader employee={employee} />
					<div className="row">
						<div className="container-fluid twoTableP" style={{display:'flex', justifyContent: 'space-between'}}>

						<div className="width100P" style={{background: 'white', width: '35%'}}>
						<div className="mob1P" style={{width: '80%', margin:'auto', paddingTop:'35px'}}>
						<div style={{marginBottom: '15px'}}>

						<div style={{display:'flex'}}>
							<img src="/portfel.png" style={{ width: '35px', marginRight:'10px'}}></img>
							{employee.stredisko !== "-" && employee.stredisko !== 'null' ? (
                                  <span style={{width: '100%', fontSize: '14px'}} className="badge badge-success green-pracuje light border-0 fontSize10">{actualTovarna}</span>
                                ) : (
                                  <span style={{width: '100%', fontSize: '14px'}} className="badge badge-danger red-pracuje light border-0 fontSize10">Nepracuje</span>
                                )}
							</div>
						</div>

						<div>
						<div style={{display:'flex'}}>
							<img src="/bed.png" style={{ width: '35px', marginRight:'10px'}}></img>
							{employee.hasOwnProperty("ubytovna") ? (
                          actualUbytovna !== "-" && actualUbytovna !== "null" ? (
                            <span style={{width: '100%'}} className={`tableUbytovna badge badge-success green-ubytovan light border-0 ${(actualRoom && actualRoom !== '-') ? '' : 'noRoomOrange'}`}>
                              {actualUbytovna}
                              {(actualRoom && actualRoom !== '-' && actualRoom !== 'null') ? `, č. ${actualRoomName}` : null}
                            </span>

                          ) : (
                            <span style={{width: '100%'}} className="tableUbytovna badge badge-danger red-neubytovan light border-0">Neubytovan</span>
                          )
                        ) : (
                          <span style={{width: '100%'}} className="tableUbytovna badge badge-danger red-neubytovan light border-0">Neubytovan</span>
                        )}
							</div>
						</div>

						<hr style={{marginTop:'35px', marginBottom: '35px'}}></hr>

						<div>
							<img src="/documents.png" style={{ width: '35px', marginRight:'10px'}}></img>
							<strong style={{fontSize:'20px', color:'black'}}>Dostupné dokumenty</strong>
						</div>

						<div style={{width: '80%', margin:'auto', marginBottom: '100px', marginTop: '25px'}}>
							<p className="menuDivsp2">Souhlas se zpracováním osobních údajů GDPR</p>
							<p className="menuDivsp2">Dohoda o dočasném přidělení zaměstnance</p>
							<p className="menuDivsp2">Pracovní smlouva na dobu určitou</p>
							<p className="menuDivsp2">Pracovní smlouva na dobu neurčitou</p>
							<p className="menuDivsp2">Informační karta ÚP</p>
							<p className="menuDivsp2">Ukončení pracovního poměru dohodou</p>
							<p className="menuDivsp2">Školení bozp při nástupu do zaměstnání</p>
						</div>
						<div style={{marginBottom:'35px', textAlign: 'center'}}>
						<button onClick={() => handleEdit(employee)} style={{width: '120px'}} class="btn btn-primary btn-sm">Změnit</button>
						</div>
						</div>
						</div>

						<div className="width100P" style={{background: 'white', width: '60%'}}>
							<div className="mob1P" style={{width: '85%', margin:'auto', paddingTop:'35px'}}>
						<strong style={{fontSize:'20px', color:'black'}}>Seznam posledních údalosti</strong>
						<EmployeeEvents employee={employee} />
						</div>
						</div>

						</div>
						{/* <div className="card">
							<div className="card-body">
								<div className="profile-tab">
									<div className="custom-tab-1">
										<Tab.Container defaultActiveKey='About'>
											<Nav as='ul' className="nav nav-tabs">
												<Nav.Item as='li' className="nav-item">
													<Nav.Link to="#about-me" eventKey='About'>About Me</Nav.Link>
												</Nav.Item>
												<Nav.Item as='li' className="nav-item">
													<Nav.Link to="#events" eventKey='Events'>Events</Nav.Link>
												</Nav.Item>
											</Nav>
											<Tab.Content>
												<Tab.Pane id="about-me" eventKey='About'>
													<PersonalInfo employee={employee} />
												</Tab.Pane>
												<Tab.Pane id="events" eventKey='Events'>
													<EmployeeEvents employee={employee} />
												</Tab.Pane> 
											</Tab.Content>
										</Tab.Container>
									</div>
								</div>
							</div>
						</div> */}
					</div>
				</div>
			</Fragment>
			<EditEmployeeOffcanvas
        ref={editEmploye}
        Title="Edit Employee"
        setDataUpdateTrigger2={setDataUpdateTrigger2}
        // setEventsUpdateTrigger={setEventsUpdateTrigger}
        selectedEmployee={selectedEmployee}
      />
		</>
	);
};

export default AppProfile;
