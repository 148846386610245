import React, { useState, forwardRef, useImperativeHandle, useEffect, useRef  } from 'react';
import { useSelector } from 'react-redux';
import {Link, useNavigate} from 'react-router-dom';
import { Offcanvas } from 'react-bootstrap';
import axios from 'axios';


const EmployeeOffcanvas = forwardRef((props, ref) => {
     const firstCompany = useSelector(state => (state.company && state.company.firstCompany) || '');
    const [hostelsData, setHostels] = useState([]);
    const [tovarnyData, setTovarny] = useState([]);
    const [selectedSelect, setSelectedSelect] = useState(null);
    const [selectedTovarna, setSelectedTovarna] = useState(null);
    const [Rooms, setRooms] = useState([]);
    const [RoomsCapacity, setRoomsCapacity] = useState([]);
    const [currentDate, setCurrentDate] = useState('');
    const [selectedHostelId, setSelectedHostelId] = useState('')
    const [selectedTovarnaId, setSelectedTovarnaId] = useState('')
    const [email, setEmail] = useState('');
    const [serverEmails, setServerEmails] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [empCards, setEmpCards] = useState({
      FirstName: '',
      LastName: '',
      birth: '',
      Nationality: '',
      Sex: '',
      Valabilitate: '',
      passID: '',

    });
  
    
    const generateRandomEmail = () => {
      let randomEmail;
      do {
        randomEmail = `${Math.random().toString(36).substring(2, 10)}@example.com`;
      } while (serverEmails.includes(randomEmail)); // Проверяем, что email не существует на сервере
      setEmail(randomEmail);
    };
  
   
    const handleButtonClick = (e) => {
      e.preventDefault();

        generateRandomEmail();    
    };

  
  

    useEffect(() => { 
    fetchData88();
    fetchDataTovarny();
    const today = new Date();
    const formattedDate = today.toISOString().split('T')[0];
    setCurrentDate(formattedDate);
  }, [firstCompany]);

  const fetchData88 = async () => {
   
    try {
      const response = await fetch(`/${firstCompany}/ubytovny.json?${Date.now()}`);
      const data = await response.json();
      setHostels(data);
      console.log('EmployeeOffCan - data', data);
    } catch (error) {
      console.error('Ошибка при загрузке данных:', error);
    }
  };

  const fetchDataTovarny = async () => {
    // var activeCompany = document.cookie.replace(/(?:(?:^|.*;\s*)activeCompany\s*\=\s*([^;]*))/, "$1");
    try {
      const response = await fetch(`/${firstCompany}/tovarny.json?${Date.now()}`);
      const data = await response.json();
      setTovarny(data);
    } catch (error) {
      console.error('Ошибка при загрузке данных:', error);
    }
  };

    const { setDataUpdateTrigger, setEventsUpdateTrigger } = props;
    const [startDate, setStartDate] = useState(new Date());
    const [startDate2, setStartDate2] = useState(new Date());
    const [addEmploye, setAddEmploye] = useState(false);

    


    const showEditEmployeModal = () => {
      setAddEmploye(true);
    };
  
    useImperativeHandle(ref, () => ({
      showEmployeModal() {
        setAddEmploye(true);
      },
      showEditEmployeModal, // Передаем метод в ref
    }));
  
    const nav = useNavigate();
   
    const handleSubmit = async (e) => {
      e.preventDefault(); 
      if (checkGenderSelection()) {
        return;
      }
      console.log(' EmployeeOffcanvas -- hostelName 0', selectedSelect);
      console.log(' EmployeeOffcanvas -- tovarnaName 0', selectedTovarna);

      const hostelNameToAdd = selectedSelect === null ? '-' : selectedSelect;
      const tovarnaNameToAdd = selectedTovarna === null ? '-' : selectedTovarna;


      const formData = new FormData(e.target);
      formData.append('firstCompany', firstCompany);
      
      
      formData.append('ubytovna', selectedHostelId);
      formData.append('hostelName', hostelNameToAdd);
      formData.append('tovarnaName', tovarnaNameToAdd);
      formData.append('gender', selectedGender); 
      formData.append('country', country);
      formData.append('stredisko', selectedTovarnaId);
      formData.append('pokojID', selectedPokoj);
      formData.append('pokojName', selectedPokojName);

      if (tovarnaNameToAdd === '-' || tovarnaNameToAdd === '' || tovarnaNameToAdd === 'null' || tovarnaNameToAdd === null) {
        const currentTime = new Date(); 
        const formattedTime = currentTime.toISOString(); 
        console.log('currentTime', currentTime);
        // formData.append('noWork', currentTime);
        // formData.append('description', 'Pracovník byl vytvořen, ale během 2 dnů nebyl zaměstnaný');
        const noWork = {
          noWork: currentTime,
          description: 'Pracovník byl vytvořen, ale během 2 dnů nebyl zaměstnaný'
        };
        formData.append('noWork', JSON.stringify(noWork));
    }

    if (hostelNameToAdd === '-' || hostelNameToAdd === '' || hostelNameToAdd === 'null' || hostelNameToAdd === null) {
      const currentTime = new Date(); 
      const formattedTime = currentTime.toISOString();  
      console.log('currentTime', currentTime);
      // formData.append('noHotel', currentTime);
      // formData.append('description', 'Pracovník byl vytvořen, ale během 2 dnů nebyl umístěn do hotelu');
      const noHotel = {
        noHotel: currentTime,
        description: 'Pracovník byl vytvořen, ale během 2 dnů nebyl umístěn do hotelu'
      };
      formData.append('noHotel', JSON.stringify(noHotel));
  } 
    


      console.log(' EmployeeOffcanvas -- formData.get(noHotel); ', formData.get('noHotel'));
      console.log(' EmployeeOffcanvas -- tovarnaName', selectedTovarna);
      console.log('formData -- EmployeeOffcanvas', formData);
      console.log('firstCompany -- EmployeeOffcanvas', firstCompany);



      try {
        // Загрузка данных из file.json
        const response = await fetch(`/${firstCompany}/file.json?${Date.now()}`);
        const data = await response.json();
        
        // Проверка на уникальность email
        const email = formData.get('email'); 
        const isEmailUnique = data.every(item => item.email !== email);
  
        if (!isEmailUnique) {
          createDeletingPopUpPokoj('Email již existuje. Zadejte prosím jiný e-mail.');
          return;
        }

        const mobile = formData.get('mobile');

        const isMobileUnique = data.every(item => item.mobile !== mobile);
  
        if (!isMobileUnique) {
          createDeletingPopUpPokoj('Telefonní číslo již existuje. Zadejte prosím jiný.');
          return;
        } 
    
        // Отправка данных на сервер
        axios.post('/save_data.php', formData) 
          .then(response => {
            console.log('save_data');
            setDataUpdateTrigger((prev) => !prev);
            setEventsUpdateTrigger((prev) => !prev);
            
            
          })
          .catch(error => {
            console.error(error);
          });

          
  
        setAddEmploye(false);
      } catch (error) {
        console.error('Ошибка при загрузке данных:', error);
      }
    };
      
    



    const fetchDataPokoje = async () => {
      // var activeCompany = document.cookie.replace(/(?:(?:^|.*;\s*)activeCompany\s*\=\s*([^;]*))/, "$1");
        try {
          const response = await fetch(`/${firstCompany}/pokoje.json?${Date.now()}`);
          const data = await response.json();

          const response2 = await fetch(`/${firstCompany}/file.json?${Date.now()}`);
          const data2 = await response2.json();

          const filteredRooms = data.filter(
            (room) =>
              room.ubytovna === selectedSelect 
          );

          console.log('filteredRooms', filteredRooms);

          const roomCount = {};

      data2.forEach((client) => {
        if (client.ubytovna === selectedSelect) {
          const key = `${client.ubytovna} *-* ${client.roomID}`;
          if (!roomCount[key]) {
            roomCount[key] = 1;
          } else {
            roomCount[key]++;
          }
        }
      });

      console.log('roomCount', roomCount);
    
      const numberOfKeys = Object.keys(roomCount).length;

      if (numberOfKeys > 0) {
        
      const roomCountArray = Object.keys(roomCount).map((key) => {
        const [ubytovna, roomID] = key.split(' *-* ');
        return { ubytovna, roomID, count: roomCount[key] };
      });

      console.log('1-roomCountArray', roomCountArray);

      const roomsWithFreeSpace = filteredRooms.map((room) => {
        const countObj = roomCountArray.find((obj) => obj.ubytovna === room.ubytovna && obj.roomID === room.id);
        if (countObj) {
            const quantity = parseInt(room.quantity, 10);
            const count = countObj.count;
            const freeSpace = quantity - count;
            return { ...room, freeSpace };
        } else {
            // Если не удалось найти соответствующий объект в roomCountArray, то считаем, что свободных мест нет.
            return { ...room, freeSpace: room.quantity };
        }
    });
    
    console.log('roomsWithFreeSpace', roomsWithFreeSpace);

    const RoomsWithSpace = roomsWithFreeSpace.filter((room) => room.freeSpace > 0);

    console.log('RoomsWithSpace', RoomsWithSpace);

  setRooms(RoomsWithSpace);
} else {
  setRooms(filteredRooms);
  console.log('filteredRooms-filteredRooms',filteredRooms);
}
          // setRooms(data);
        } catch (error) {
          console.error('Ошибка при загрузке данных:', error);
        }
      };
     


      const fetchDataFile = async () => {
        try {
          // var activeCompany = document.cookie.replace(/(?:(?:^|.*;\s*)activeCompany\s*\=\s*([^;]*))/, "$1");
          const response = await fetch(`/${firstCompany}/file.json?${Date.now()}`);
          const data = await response.json();
      
          
          const roomCounts = {};
          const emailList = data.map(item => item.email);
           
          setServerEmails(emailList);
          

          // Подсчитываем количество объектов с одинаковыми значениями ключа "room"
          data.forEach(obj => {
            if (obj.hasOwnProperty('room')) {
              const roomValue = obj.roomID;
              roomCounts[roomValue] = (roomCounts[roomValue] || 0) + 1;
            }
          });
      
           setRoomsCapacity(roomCounts);
      
        } catch (error) {
          console.error('Ошибка при загрузке данных:', error);
        }
      };


      useEffect(() => {
        
        fetchDataPokoje();
        fetchDataFile();
      }, []);


      useEffect(() => {
       
        fetchDataPokoje();
        setTimeout(() => {
          console.log('Rooms1', Rooms);
        },1000);
      }, [selectedSelect]);
    
      

    // select tovarna :
    const [isOpenTovarna, setIsOpenTovarna] = useState(false);
  
    const handleTovarnaSelect = (tovarna) => {
      setSelectedTovarnaId(tovarna.id);
      setSelectedTovarna(tovarna.name);
      setIsOpenTovarna(false); // Close dropdown after selection
    };

    // END select tovarna

      
// Select State:

const [isOpen, setIsOpen] = useState(false);
const [country, setCountry] = useState('');

    const [selectedValue, setSelectedValue] = useState({ label: 'Prosím vyberte', icon: '' });
    const selectRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (selectRef.current && !selectRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleSelect = (value, label, icon) => {
        setSelectedValue({ label, icon });
        setCountry(value);
        
        
        // Обновляем поле "Nationality" в объекте empCards
  setEmpCards((prevState) => ({
    ...prevState,
    Nationality: value, // Сохраняем значение страны в поле "Nationality"
  }));
  setIsOpen(false);
    };

// Select Pohlavi END

// Select Ubytovna:

const handleUbytovnaSelect = (selectedHostelName) => {
  const selectedHostel = hostelsData.find(hostel => hostel.id === selectedHostelName);
  setSelectedHostelId(selectedHostel ? selectedHostel.id : '');
  setSelectedSelect(selectedHostel.name);
  setSelectedPokoj('-');
};

const [showOptions, setShowOptions] = useState(false);


// END  Select Ubytovna 


// Select Pokoj

const [showOptionsPokoj, setShowOptionsPokoj] = useState(false);
const [selectedPokoj, setSelectedPokoj] = useState('');
const [selectedPokojName, setSelectedPokojName] = useState('');

const handlePokojSelect = (selectedRoomId) => {
  setSelectedPokoj(selectedRoomId);
    console.log(`Выбрана комната с id: ${selectedRoomId}`);
    
};

const handlePokojSelectName = (selectedRoomName) => {
  setSelectedPokojName(selectedRoomName);
    console.log(`Выбрана комната name: ${selectedRoomName}`);
    
};

// END Select Pokoj

// Pohlavi: 


const [selectedGender, setSelectedGender] = useState(null);

// const handleGenderChange = (gender) => {
//   setSelectedGender(gender);
// };

const handleGenderChange = (gender) => {
  setEmpCards((prevState) => ({
    ...prevState,
    Sex: gender, // Обновляем ключ "Sex"
  }));
  setSelectedGender(gender);
};

const checkGenderSelection = () => {
  if (!selectedGender) {
    alert("Vyberte prosím pohlaví.");
    return true; 
  }
  return false; 
};
// Pohlavi END
  

function createDeletingPopUpPokoj(neededText) {
    
  var overlay = document.createElement("div");
  overlay.className = "overlay";

  var popup = document.createElement("div");
  popup.className = "popup"; 

  var buttonspopup = document.createElement("div");
  buttonspopup.className = "buttonspopup";

  
  var text = document.createElement("p");
  text.textContent = neededText;

  var logoPoUp = document.createElement("img");
  logoPoUp.src = '/static/media/Logo%20OWB%20website.f8178ceeb0449f70be9f.png';
  logoPoUp.classList.add('logoPoUp');
  
  var yesButton = document.createElement("button");  
  yesButton.classList.add('btn', 'btn-primary');
  yesButton.textContent = "Zavřít";
  yesButton.addEventListener("click", function () {
    //  удаление
    closePopUp(overlay, popup);
  });


  
  popup.appendChild(logoPoUp);
  popup.appendChild(text);
  buttonspopup.appendChild(yesButton);
  popup.appendChild(buttonspopup);
  document.body.appendChild(overlay);
  document.body.appendChild(popup);


}

function closePopUp(overlay, popup) {
  overlay.remove();
  popup.remove();
  // clearTimeout(timerPopUp);
}

// Upload photo, take a photo:
const [imageUrl, setImageUrl] = useState('');


 // Функция для загрузки изображения на сервер
 const uploadImage = (file) => {
  const formData = new FormData();
  formData.append('image', file);

  fetch('https://test1.jobsi.app/upload.php', {
    method: 'POST',
    body: formData,
  })
  .then(response => {
    if (!response.ok) {
      throw new Error(`Ошибка загрузки: ${response.status}`);
    }
    return response.json();
  })
    .then((data) => {
      setImageUrl(`https://test1.jobsi.app/${data.imageUrl}`); // Сохраняем URL загруженного изображения
      console.log('Image URL:', `https://test1.jobsi.app/${data.imageUrl}`);
      imageToServer(data.imageUrl); // Вызов функции после загрузки изображения
    })
    .catch((error) => {
      console.error('Ошибка загрузки изображения:', error);
    });
};


// Функция для отправки URL изображения на другой сервер
const imageToServer = (imageUrl) => {
  fetch(
    'https://marketing-agent.eu/process-image?imageUrl=' +
      encodeURIComponent(`https://test1.jobsi.app/${imageUrl}`)
  )
    .then((response) => response.text())
    .then((text) => {
      console.log('Текст с изображения:', text);
      const idCardData = parseIdCard(text);
      setEmpCards(idCardData);
      console.log(idCardData);
    })
    .catch((error) => {
      console.error('Ошибка:', error);
    });
};

// Функция для обработки загруженного файла изображения
const handleFileUpload = (event) => {
  const file = event.target.files[0];
  if (file) {
    uploadImage(file);
  }
};


// Функция для создания объекта из текста
function parseIdCard(text) {
    const lines = text.trim().split('\n').map(line => line.trim());
    const data = {};
    console.log('lines', lines);

    const excludedKeywordsUKR = [
        "Ж/F", "UKR", "UKRAINE", "Nationality", "Туре", 
        "Date", "signature", "SPECIMEN", "Personal", "Passport", 
        "Surname", "names", "birth", "Authority"
    ];

    const excludedKeywordsLATVIA = [
        "LATVIA", "LVA", "LATVIJAS", "Sex", "Date", "Type", 
        "Nationality", "Personal", "Passport", "Surname", "name", "Prénom", "Given", "Nom"
    ];

    const excludedKeywordsPassportName = [
        "LATVIA", "LVA", "LATVIJAS", "Sex", "Date", "Type", 
        "Nationality", "Personal", "Passport", "Surname", "name", "Prénom", "Given", "Nom", "Given names", "PASSPORT", "issuing State"
    ];

    const excludedKeywordsCZidNames = [
        "UTE", "CZE", "RUS", "DATUM NAROZENÍ", "DRUH POVOLENÍ", "TRVALÝ POBYT", "POVOLENÍ K POBYTU PRO", "PLATNOST DO",
        "DLOUHODOBĚ", "POZNÁMKY", "EU", "Residence permit"
    ];


    // Функция для проверки наличия цифр в строке
    function containsDigits(line) {
        return /\d/.test(line);
    }

    // Регулярное выражение для поиска номера: две буквы, затем цифры
const regex = /^[A-Z]{2}\d+$/;

const findPassportNumber = (lines) => {
    return lines.find(text => {
        const words = text.split(' ');
        return words.some(word => regex.test(word));
    });
};

function findLongestNumber(arr) {
    let longestNumber = ''; // Переменная для хранения самого длинного числа

    arr.forEach((line) => {
        // Удаляем пробелы и проверяем, состоит ли строка только из цифр
        const trimmedLine = line.replace(/\s+/g, ''); // Убираем все пробелы

        // Проверяем, является ли строка числом
        if (/^\d+$/.test(trimmedLine) && trimmedLine.length > longestNumber.length) {
            longestNumber = trimmedLine; // Обновляем самое длинное число
        }
    });

    return longestNumber; // Возвращаем самое длинное число
}


function findSingleLetter(arr) {
    for (const line of arr) {
        // Убираем пробелы и проверяем, состоит ли строка из одной буквы
        const trimmedLine = line.trim();

        // Проверяем, что строка состоит ровно из одной буквы "M" или "Ž"
        if (trimmedLine.length === 1 && (trimmedLine === 'M' || trimmedLine === 'Ž')) {
            return trimmedLine; // Возвращаем найденную букву
        }
    }

    return null; // Если не нашли, возвращаем null
}

const result = findPassportNumber(lines);

     if (text.includes('CZE')) {
        let prijmeniIndex = 0;
        // Проверка строк на наличие фамилии и имени
        lines.forEach((line, index) => {
            // Проверка фамилии
            if ((line.includes("PŘÍJMENÍ"))) {
    let i = index + 1;  // Начинаем проверку со строки после "Given names"
    
    // Продолжаем проверку строк, пока не найдем подходящую
    while (i < lines.length) {
        const nextLine = lines[i].trim();  // Убираем лишние пробелы
        
        // Проверяем, что строка не содержит ключевых слов, цифр и содержит только латинские буквы
        if (nextLine && 
    nextLine.length > 3 && 
    !excludedKeywordsCZidNames.some(keyword => nextLine.includes(keyword)) && 
    !containsDigits(nextLine) &&
    /^[A-Za-z\s]+$/.test(nextLine)) {  // Проверяем на латинские буквы

            const parts = nextLine.split('/');
            if (parts.length > 1) {
                // Извлекаем английское имя, которое идет после "/"
                data.LastName = parts[1].trim();
            } else {
                // Если нет "/", берем всю строку как имя
                data.LastName = nextLine.trim();
            }
            prijmeniIndex = i;
            break;  // Имя найдено, прекращаем цикл
        }
        
        i++;  // Переходим на следующую строку
    }
}
            
            // Проверка имени
            if ((line.includes("Jména"))) {
    let i = prijmeniIndex + 1;  // Начинаем проверку со строки после "Given names"
    
    // Продолжаем проверку строк, пока не найдем подходящую
    while (i < lines.length) {
        const nextLine = lines[i].trim();  // Убираем лишние пробелы
        
        // Проверяем, что строка не содержит ключевых слов, цифр и содержит только латинские буквы
        if (nextLine && 
    nextLine.length > 3 && 
    !excludedKeywordsCZidNames.some(keyword => nextLine.includes(keyword)) && 
    !containsDigits(nextLine) &&
    /^[A-Za-z\s]+$/.test(nextLine)) {  // Проверяем на латинские буквы

            const parts = nextLine.split('/');
            if (parts.length > 1) {
                // Извлекаем английское имя, которое идет после "/"
                data.FirstName = parts[1].trim();
            } else {
                // Если нет "/", берем всю строку как имя
                data.FirstName = nextLine.trim();
            }
            
            break;  // Имя найдено, прекращаем цикл
        }
        
        i++;  // Переходим на следующую строку
    }
}


            
            // Проверка других данных
            if (line.includes("STÁTNÍ PŘÍSLUŠNOST")) {
    let i = index + 1; 

    while (i < lines.length) {
        const nextLine = lines[i].trim(); // Убираем лишние пробелы
        
        // Проверяем, состоит ли строка из 2 или 3 букв (включая "RUS")
        if (/^[A-Za-zŽž]{2,3}$/.test(nextLine)) {
            data.Nationality = nextLine;  // Записываем значение, если условие выполнено
            break;  
        }
        
        i++;  
    }
}



             if (line.includes("DATUM NAROZENÍ")) {
    let i = index + 1;  // Начинаем проверку со строки после "Date of expiry"
    
    // Продолжаем проверку строк, пока не найдем подходящую
    while (i < lines.length) {
        const nextLine = lines[i].trim();  // Убираем лишние пробелы
        console.log('expiry', nextLine);
        // Проверяем, что строка содержит только цифры и точки
        if (/^\d+(?: \d+)*$/.test(nextLine) && (nextLine.match(/\s/g) || []).length >= 2)
 {  // Проверяем на цифры и точки
            
            data.birth = nextLine;  // Сохраняем дату
            break;  // Дата найдена, прекращаем цикл
        }
        
        i++;  // Переходим на следующую строку
    }
}
if (line.includes("PLATNOST DO")) {
    let i = index + 1;  // Начинаем проверку со строки после "Date of expiry"
    
    // Продолжаем проверку строк, пока не найдем подходящую
    while (i < lines.length) {
        const nextLine = lines[i].trim();  // Убираем лишние пробелы
        console.log('expiry', nextLine);
        // Проверяем, что строка содержит только цифры и точки
        if (/^\d+(?: \d+)*$/.test(nextLine) && (nextLine.match(/\s/g) || []).length >= 2)
 {  // Проверяем на цифры и точки
            
            data.Valabilitate = nextLine;  // Сохраняем дату
            break;  // Дата найдена, прекращаем цикл
        }
        
        i++;  // Переходим на следующую строку
    }
}

        });

        
          data.passID = findLongestNumber(lines);
        
          data.Sex = findSingleLetter(lines)

      
    }

    // Обработка Румынского паспорта
    else if (text.includes('ROMANIA')) {
        lines.forEach((line, index) => {
            if (line.startsWith("Nume/Nom/Last name")) {
                data.LastName = lines[index + 1]?.trim();
            } else if (line.startsWith("Prenume/Prenom/First name")) {
                data.FirstName = lines[index + 1]?.trim();
            } else if (line.startsWith("Cetatenie/Nationalite/Nationality") || line.startsWith("Cetățenie/Nationalite/Nationality")) {
                data.Nationality = lines[index + 1]?.trim().split('/')[1]?.trim();
            } else if (line.startsWith("Sex/Sexe/Sex")) {
                data.Sex = lines[index + 1]?.trim();
            } else if (line.startsWith("Valabilitate/Validite/Validity")) {
                const validityRange = lines[index + 1]?.trim();
                if (validityRange) {
                    const parts = validityRange.split('-');
                    if (parts.length > 1) {
                        data.Valabilitate = parts[1]?.trim();
                    }
                }
            } else if (line.includes("SERIA") && line.includes("NR")) {
                const parts = line.split(' ');
                const seriaIndex = parts.indexOf("SERIA");
                const nrIndex = parts.indexOf("NR");
                if (seriaIndex !== -1 && nrIndex !== -1 && nrIndex > seriaIndex) {
                    data.passID = `${parts[seriaIndex + 1]} ${parts[nrIndex + 1]}`.trim();
                }
            } else if (line.startsWith("CNP")) {
                data.CNP = line.split(' ')[1]?.trim();
    
                // Извлечение даты рождения из CNP
                const cnp = data.CNP;
                if (cnp.length === 13) {
                    const year = cnp.substring(1, 3); // Год
                    const month = cnp.substring(3, 5); // Месяц
                    const day = cnp.substring(5, 7); // День
                    
                    // Определение полного года на основе первой цифры
                    const century = cnp.charAt(0) === '1' || cnp.charAt(0) === '2' ? '19' : '20';
                    const birthYear = century + year;
    
                    // Форматирование даты рождения
                    data.birth = `${day}.${month}.${birthYear.substring(2)}`;
                }
            }
        });

    // Обработка Украинского паспорта
    } else  if (text.includes('UKRAINE')) {
        let foundLastName = false;
        let foundFirstName = false;
        let currentIndexSurname = 0; 
        let currentIndexName = 0;

        // Проверка строк на наличие фамилии и имени
        lines.forEach((line, index) => {
            // Проверка фамилии
            if ((line.includes("Surname") || line.includes("Прізвище")) && !foundLastName) {
               
                const nextLine = lines[index + 1];
                    console.log("nextLine", nextLine);
                if (nextLine && !excludedKeywordsUKR.some(keyword => nextLine.includes(keyword)) && !containsDigits(nextLine)) {
                    const parts = nextLine.split('/');
                    data.LastName = parts[1]?.trim(); // Английский вариант
                    foundLastName = true;
                    currentIndexSurname = index + 2;
                }
            }
            
            // Проверка имени
            if ((line.includes("Names") || line.includes("Ім'я")) && !foundFirstName) {
                const nextLine = lines[index + 1];
                if (nextLine && !excludedKeywordsUKR.some(keyword => nextLine.includes(keyword)) && !containsDigits(nextLine)) {
                    const parts = nextLine.split('/');
                    data.FirstName = parts[1]?.trim(); // Английский вариант
                    foundFirstName = true;
                    currentIndexName = index + 2;
                }
            }
            
            // Проверка других данных
            if (line.includes("Nationality")) {
                data.Nationality = lines[index + 1]?.trim().split('/')[1]?.trim();
            } else if (line.includes("Date of birth")) {
                const parts = lines[index + 1]?.split('/');
                data.birth = parts[1]?.trim();
            } else if (line.includes("Date of expiry")) {
                const parts = lines[index + 1]?.split('/');
                data.Valabilitate = parts[1]?.trim();
            }
        });

        // Извлечение номера паспорта из последней строки
        const lastLine = lines[lines.length - 1]; // Самая последняя строка
        const passportNumber = lastLine.split('<')[0]; // Все до первого "<"
        data.passID = passportNumber;

        // Поиск строки с полом
        lines.forEach(line => {
            if (line === 'Ж/F') {
                data.Sex = 'F';
            } else if (line === 'М/M') {
                data.Sex = 'M';
            }
        });

         // Если фамилия и имя не найдены, ищем в последующих строках  
        
    if (!foundFirstName) {
 
        for (let i = currentIndexName; i < lines.length; i++) {
            let line = lines[i].trim();
            if (!excludedKeywordsUKR.some(keyword => line.includes(keyword)) && !containsDigits(line)) {
                const parts = line.split('/');
                if (parts.length > 1) {
                    if (!foundLastName) {
                        data.LastName = parts[1]?.trim();
                        foundLastName = true;
                    } else if (!foundFirstName) {
                        data.FirstName = parts[1]?.trim();
                        foundFirstName = true;
                    }
                }
            }
            if (foundLastName && foundFirstName) break;
        }
    }

    if (!foundLastName) {
   
        for (let i = currentIndexSurname; i < lines.length; i++) {
            let line = lines[i].trim();
            if (!excludedKeywordsUKR.some(keyword => line.includes(keyword)) && !containsDigits(line)) {
                const parts = line.split('/');
                if (parts.length > 1) {
                    if (!foundLastName) {
                        data.LastName = parts[1]?.trim();
                        foundLastName = true;
                    } else if (!foundFirstName) {
                        data.FirstName = parts[1]?.trim();
                        foundFirstName = true;
                    }
                }
            }
            if (foundLastName && foundFirstName) break;
        }
    }
    }  else if (text.includes('LATVIA')) {
        let foundLastName = false;
        let foundFirstName = false;
        let currentIndexSurname = 0; 
        let currentIndexName = 0;

        // Проверка строк на наличие фамилии и имени
        lines.forEach((line, index) => {
            // Проверка фамилии
            if ((line.includes("Surname") || line.includes("Uzvārds")) && !foundLastName) {
                const nextLine = lines[index + 1];
                if (nextLine && !excludedKeywordsLATVIA.some(keyword => nextLine.includes(keyword)) && !containsDigits(nextLine)) {
                    data.LastName = nextLine.trim();
                    foundLastName = true;
                    currentIndexSurname = index + 2;
                }
            }
            
            // Проверка имени
            if ((line.includes("Värds") || line.includes("Given") || line.includes("Vands")) && !foundFirstName) {
                const nextLine = lines[index + 1];
                if (nextLine && !excludedKeywordsLATVIA.some(keyword => nextLine.includes(keyword)) && !containsDigits(nextLine)) {
                    data.FirstName = nextLine.trim();
                    foundFirstName = true;
                    currentIndexName = index + 2;
                }
            }

            // Проверка других данных
            if (line.includes("Nationality")) {
                data.Nationality = lines[index + 1]?.trim();
            } else if (line.includes("Date of birth")) {
               if (containsDigits(lines[index + 1]?.trim())) {
                data.birth = lines[index + 1]?.trim();
               } else {
                if (containsDigits(lines[index + 2]?.trim())) {
                data.birth = lines[index + 2]?.trim();
               }
               }
                
            } else if (line.includes("Date of expiry") || (line.includes('expiration'))) {
                if (containsDigits(lines[index + 1]?.trim())) {
                data.Valabilitate = lines[index + 1]?.trim();
                } else {
                    if (containsDigits(lines[index + 2]?.trim())) {
                data.Valabilitate = lines[index + 2]?.trim();
               }
                }
            }
        });

        // Извлечение номера паспорта из последней строки
        const lastLine = lines[lines.length - 1]; // Самая последняя строка
        const passportNumber = lastLine.split('LVA')[0]; // Все до первого "<"
        data.passID = passportNumber;

        // Поиск строки с полом
        lines.forEach(line => {
            if (line.includes('S/F')) {
                data.Sex = 'F';
            } else if (line.includes('M/M')) {
                data.Sex = 'M';
            }
        });

        // Если фамилия и имя не найдены, ищем в последующих строках  
        if (!foundFirstName) {
            for (let i = currentIndexName; i < lines.length; i++) {
                let line = lines[i].trim();
                if (!excludedKeywordsLATVIA.some(keyword => line.includes(keyword)) && !containsDigits(line)) {
                    if (!foundFirstName) {
                        data.FirstName = line.trim();
                        foundFirstName = true;
                    }
                }
                if (foundFirstName && foundLastName) break;
            }
        }

        if (!foundLastName) {
            for (let i = currentIndexSurname; i < lines.length; i++) {
                let line = lines[i].trim();
                if (!excludedKeywordsLATVIA.some(keyword => line.includes(keyword)) && !containsDigits(line)) {
                    if (!foundLastName) {
                        data.LastName = line.trim();
                        foundLastName = true;
                    }
                }
                if (foundLastName && foundFirstName) break;
            }
        }
    } else if (text.includes('SLOVENSKÁ') || (text.includes('SLOVAK REP'))) {
        lines.forEach((line, index) => {
            if (line.startsWith("Priezvisko") || line.includes('Surname')) {
                data.LastName = lines[index + 1]?.trim();
            } else if (line.startsWith("Mene") || line.includes('Given names')) {
                data.FirstName = lines[index + 1]?.trim();
            } else if (line.includes("Nationality")) {
                data.Nationality = 'SVK';
            } else if (line.includes("Pohlavie") || line.includes("Sex")) {
                data.Sex = lines[index + 1]?.trim();
            } 
            else if (line.startsWith("Datum") || line.includes('Date of expiry')) {
                if (containsDigits(lines[index + 1]?.trim())) {
                data.Valabilitate = lines[index + 1]?.trim();
                } else {
                    if (containsDigits(lines[index + 2]?.trim())) {
                data.Valabilitate = lines[index + 2]?.trim();
               }
                }
            } else if (line.startsWith("Dátum narodenia") || line.includes('Date of birth')) {
                if (containsDigits(lines[index + 1]?.trim())) {
                data.birth = lines[index + 1]?.trim();
                } else {
                    if (containsDigits(lines[index + 2]?.trim())) {
                data.birth = lines[index + 2]?.trim();
               }
                }
            } 
                    data.passID = result;
                
            
        });

    if (data.sex != 'F' && data.sex != 'M') {
        lines.forEach(line => {
            if (line.includes('F')) {
                data.Sex = 'F';
            } else if (line.includes('M')) {
                data.Sex = 'M';
            }
        });
    }
    } else if (text.includes('BULGARIA') || (text.includes('БЪЛГАРИЯ'))) {
        lines.forEach((line, index) => {
            if (line.startsWith("Surname")) {
                data.LastName = lines[index + 1]?.trim();
            } else if (line.startsWith("Name")) {
                data.FirstName = lines[index + 1]?.trim();
            } else if (line.includes("Nationality")) {
                data.Nationality = 'BGR';
            } 
            
            // else if (line.includes("Pohlavie") || line.includes("Sex")) {
            //     data.Sex = lines[index + 1]?.trim();
            // } 
            
            else if (line.startsWith("Валидност") || line.includes('Date of expiry')) {
                if (containsDigits(lines[index + 1]?.trim())) {
                data.Valabilitate = lines[index + 1]?.trim();
                } else {
                    if (containsDigits(lines[index + 2]?.trim())) {
                data.Valabilitate = lines[index + 2]?.trim();
               }
                }
            } else if (line.startsWith("Дата на раждане") || line.includes('Date of birth')) {
                if (containsDigits(lines[index + 1]?.trim())) {
                data.birth = lines[index + 1]?.trim();
                } else {
                    if (containsDigits(lines[index + 2]?.trim())) {
                data.birth = lines[index + 2]?.trim();
               }
                }
            } else if (line.includes('Document number')) {
           
                if (containsDigits(lines[index + 1]?.trim())) {
                    data.passID = lines[index + 1]?.trim();
                } else if (containsDigits(lines[index + 2]?.trim())) {
                    data.passID = lines[index + 2]?.trim();
                } else if (containsDigits(lines[index + 3]?.trim())) {
                    data.passID = lines[index + 3]?.trim();
                }
            

               
            }
                    
                
            
        });

    
        // Поиск строки с полом
        lines.forEach(line => {
            if (line.includes('Ж/F')) {
                data.Sex = 'F';
            } else if (line.includes('M/M')) {
                data.Sex = 'M';
            }
        });
    
    } else if (text.includes('PASSPORT')) {
      let foundLastName = false;
      let foundFirstName = false;
      let currentIndexSurname = 0; 
      let currentIndexName = 0;

      // Проверка строк на наличие фамилии и имени
      lines.forEach((line, index) => {
          // Проверка фамилии
          if ((line.includes("Surname"))) {
  let i = index + 1;  // Начинаем проверку со строки после "Given names"
  
  // Продолжаем проверку строк, пока не найдем подходящую
  while (i < lines.length) {
      const nextLine = lines[i].trim();  // Убираем лишние пробелы
      
      // Проверяем, что строка не содержит ключевых слов, цифр и содержит только латинские буквы
      if (nextLine && 
          !excludedKeywordsPassportName.some(keyword => nextLine.includes(keyword)) && 
          !containsDigits(nextLine) &&
          /^[A-Za-z\s]+$/.test(nextLine)) {  // Проверяем на латинские буквы

          const parts = nextLine.split('/');
          if (parts.length > 1) {
              // Извлекаем английское имя, которое идет после "/"
              data.LastName = parts[1].trim();
          } else {
              // Если нет "/", берем всю строку как имя
              data.LastName = nextLine.trim();
          }
          
          break;  // Имя найдено, прекращаем цикл
      }
      
      i++;  // Переходим на следующую строку
  }
}
          
          // Проверка имени
          if ((line.includes("Given names"))) {
  let i = index + 1;  // Начинаем проверку со строки после "Given names"
  
  // Продолжаем проверку строк, пока не найдем подходящую
  while (i < lines.length) {
      const nextLine = lines[i].trim();  // Убираем лишние пробелы
      
      // Проверяем, что строка не содержит ключевых слов, цифр и содержит только латинские буквы
      if (nextLine && 
          !excludedKeywordsPassportName.some(keyword => nextLine.includes(keyword)) && 
          !containsDigits(nextLine) &&
          /^[A-Za-z\s]+$/.test(nextLine)) {  // Проверяем на латинские буквы

          const parts = nextLine.split('/');
          if (parts.length > 1) {
              // Извлекаем английское имя, которое идет после "/"
              data.FirstName = parts[1].trim();
          } else {
              // Если нет "/", берем всю строку как имя
              data.FirstName = nextLine.trim();
          }
          
          break;  // Имя найдено, прекращаем цикл
      }
      
      i++;  // Переходим на следующую строку
  }
}


          
          // Проверка других данных
          if (line.includes("Nationality")) {
              data.Nationality = lines[index + 1]?.trim().split('/')[1]?.trim();
          } 
           if (line.includes("Date of birth")) {
  let i = index + 1;  // Начинаем проверку со строки после "Date of expiry"
  
  // Продолжаем проверку строк, пока не найдем подходящую
  while (i < lines.length) {
      const nextLine = lines[i].trim();  // Убираем лишние пробелы
      console.log('expiry', nextLine);
      // Проверяем, что строка содержит только цифры и точки
      if (/^\d+(\.\d+)*$/.test(nextLine) || /^[A-Za-z0-9]+$/.test(nextLine)) {  // Проверяем на цифры и точки
          
          data.birth = nextLine;  // Сохраняем дату
          break;  // Дата найдена, прекращаем цикл
      }
      
      i++;  // Переходим на следующую строку
  }
}
           if (line.includes("Date of expiry")) {
  let i = index + 1;  // Начинаем проверку со строки после "Date of expiry"
  
  // Продолжаем проверку строк, пока не найдем подходящую
  while (i < lines.length) {
      const nextLine = lines[i].trim();  // Убираем лишние пробелы
      console.log('expiry', nextLine);
      // Проверяем, что строка содержит только цифры и точки
      if (/^\d+(\.\d+)*$/.test(nextLine)) {  // Проверяем на цифры и точки
          
          data.Valabilitate = nextLine;  // Сохраняем дату
          break;  // Дата найдена, прекращаем цикл
      }
      
      i++;  // Переходим на следующую строку
  }
}

      });

      // Извлечение номера паспорта из последней строки
      const lastLine = lines[lines.length - 1]; // Самая последняя строка
      const rusIndex = lastLine.indexOf("RUS"); // Индекс первого вхождения "RUS"

      if (rusIndex !== -1) {
          // Если "RUS" найдено, извлекаем строку до него
          data.passID = lastLine.substring(0, rusIndex).trim(); 
      } else {
          // Если "RUS" не найдено, извлекаем до первого "<"
          data.passID = lastLine.split('<')[0].trim(); 
      }

      // Поиск строки с полом
      lines.forEach(line => {
          if (line.includes('/F')) {
              data.Sex = 'F';
          } else if (line.includes('/M')) {
              data.Sex = 'M';
          }
      });

     
  }
    
    // Заполнить отсутствующие ключи пустыми строками
    data.Nationality = data.Nationality || '';
    data.Valabilitate = data.Valabilitate || '';
    data.passID = data.passID || '';
    data.Sex = data.Sex || '';

    return data;
}

// const idCardData = parseIdCard(text);
// console.log(idCardData);



const handleInputChange = (e) => {
  const { name, value } = e.target; 
  setEmpCards((prevState) => ({
    ...prevState, // Сохранение предыдущих полей
    [name]: value, // Обновление поля по имени инпута
  }));
};

const manRef = useRef(null);  // Ссылка на div с "Mužský"
const womanRef = useRef(null); // Ссылка на div с "Ženský"

// useEffect для симуляции клика при загрузке компонента
useEffect(() => {
  if (empCards.Sex === "M" && manRef.current) {
    manRef.current.click();  // Программный клик по "Mužský"
  } else if (empCards.Sex === "F" && womanRef.current) {
    womanRef.current.click();  // Программный клик по "Ženský"
  }
}, [empCards.Sex]);


const selectCZRef = useRef(null);  
const selectUARef = useRef(null); 
const selectPLRef = useRef(null);  
const selectRORef = useRef(null); 
const selectLTRef = useRef(null);  
const selectLVRef = useRef(null); 
const selectMDRef = useRef(null);  
const selectBGRef = useRef(null); 
const selectHURef = useRef(null);  
const selectAZRef = useRef(null); 
const selectSKRef = useRef(null);  

useEffect(() => {
  const normalizedNationality = empCards.Nationality.toLowerCase(); // Приводим значение к нижнему регистру


  if (['cz', 'czech', 'cze'].includes(normalizedNationality)) {
     handleSelect('CZ', 'CZ', '/cz.jpg');
   // selectCZRef.current?.click();
  }
  
  else if (['ua', 'ukraine', 'ukr'].includes(normalizedNationality)) {
    handleSelect('UA', 'UA', '/ua.jpg');
    //selectUARef.current?.click();
  }
  
  else if (['pl', 'poland'].includes(normalizedNationality)) {
    handleSelect('PL', 'PL', '/pl.webp');
   // selectPLRef.current?.click();
  } 
  

  else if (['ro', 'rou', 'romania'].includes(normalizedNationality)) {
    handleSelect('RO', 'RO', '/ro.jpg');
   // selectRORef.current?.click();
  } 


  else if (['lv', 'latvija', 'lva', 'latvia', 'latvijas'].includes(normalizedNationality)) {
    handleSelect('LV', 'LV', '/lv.jpg');
    //selectLVRef.current?.click();
  } 
  

  else if (['sk', 'svk', 'slovak'].includes(normalizedNationality)) {
    handleSelect('SK', 'SK', '/sk.jpg');
    // selectSKRef.current?.click();
  } 
  

  else if (['bg', 'bgr'].includes(normalizedNationality)) {
    handleSelect('BG', 'BG', '/bgr.jpg');
    // selectBGRef.current?.click();
  }

  else if (['lt'].includes(normalizedNationality)) {
    handleSelect('LT', 'LT', '/lt.jpg');
   // selectLTRef.current?.click();
  }


  else if (['md'].includes(normalizedNationality)) {
    handleSelect('MD', 'MD', '/md.jpg');
    // selectMDRef.current?.click();
  }

  else if (['hu'].includes(normalizedNationality)) {
    handleSelect('HU', 'HU', '/hu.jpg');
    // selectHURef.current?.click();
  }

  else if (['az'].includes(normalizedNationality)) {
    handleSelect('AZ', 'AZ', '/az.jpg');
    //selectAZRef.current?.click();
  }
 
}, [empCards.Nationality]);


// useEffect для программного выбора национальности
// useEffect(() => {
//   switch (empCards.Nationality) {
//     case 'CZ':
//       handleSelect('CZ', 'CZ', '/cz.jpg');
//       break;
//     case 'UA':
//       handleSelect('UA', 'UA', '/ua.jpg');
//       break;
//     case 'PL':
//       handleSelect('PL', 'PL', '/pl.webp');
//       break;
//     case 'LT':
//       handleSelect('LT', 'LT', '/lt.jpg');
//       break;
//     case 'LV':
//       handleSelect('LV', 'LV', '/lv.jpg');
//       break;
//     case 'RO':
//       handleSelect('RO', 'RO', '/ro.jpg');
//       break;
//     case 'MD':
//       handleSelect('MD', 'MD', '/md.jpg');
//       break;
//     case 'HU':
//       handleSelect('HU', 'HU', '/hu.jpg');
//       break;
//     case 'AZ':
//       handleSelect('AZ', 'AZ', '/az.jpg');
//       break;
//     case 'SK':
//       handleSelect('SK', 'SK', '/sk.jpg');
//       break;
//     case 'BG':
//       handleSelect('BG', 'BG', '/bgr.jpg');
//       break;
//     default:
//       break;
//   }
// }, [empCards.Nationality]);




    return (
        <>
            <Offcanvas show={addEmploye} onHide={setAddEmploye} className="offcanvas-end customeoff" placement='end'>
				<div className="offcanvas-header">
              
					<h5 className="modal-title" id="#gridSystemModal">Nový zaměstnanec</h5>
          
         {/* <button onClick={()=>imageToServer()}>Take Photo</button> */}
          {/* <button onClick={() => document.getElementById('cameraInput').click()}>
        Take a photo
      </button>
      <input
        type="file"
        id="cameraInput"
        accept="image/*"
        capture="camera"
        onChange={handleFileUpload}
        style={{ display: 'none' }}
      /> */}


      {/* Кнопка для загрузки файла */}


      {/* <button onClick={() => document.getElementById('fileInput').click()}>
        Upload photo
      </button>
      <input
        type="file"
        id="fileInput"
        accept=".png, .jpg, .jpeg, .webp"
        onChange={handleFileUpload}
        style={{ display: 'none' }}
      /> */}
					<button type="button" className="btn-close" 
						onClick={()=>setAddEmploye(false)}
					>
						<i className="fa-solid fa-xmark"></i>
					</button>
				</div>
        
				<div className="offcanvas-body">
                    <div className="container-fluid">

                    <div  className='scanDiv'>
          <div style={{paddingRight: '0.74rem'}} className='col-xl-6 mb-3'>
          <button onClick={() => document.getElementById('cameraInput').click()}>
            <p style={{ marginRight: '15px'}}>Vyfotit doklad totožnosti</p>
            <img src="/vyfotitDocument.png" style={{ width: '30px' }}></img>
            </button>
      <input
        type="file"
        id="cameraInput"
        accept="image/*"
        capture="camera"
        onChange={handleFileUpload}
        style={{ display: 'none' }}
      />
          </div>
          <div style={{paddingLeft: '0.74rem'}} className='col-xl-6 mb-3'>
          <button onClick={() => document.getElementById('fileInput').click()}>
          <p style={{ marginRight: '15px' }}>Nahrát doklad totožnosti</p>
          <img src="/uploadDocument.png" style={{ width: '30px' }}></img>
          </button> 
      <input
        type="file"
        id="fileInput"
        accept=".png, .jpg, .jpeg, .webp"
        onChange={handleFileUpload}
        style={{ display: 'none' }}
      />
          </div>
        </div>
                        
                        <form onSubmit={handleSubmit}>
                            <div className="row">
                            
                            <div className="col-xl-6 mb-3">
  <label htmlFor="exampleFormControlInput2" className="form-label">
    Jméno <span className="text-danger">*</span>
  </label>
  <input
    type="text"
    name="name"
    className="form-control"
    id="exampleFormControlInput2"
    required
    value={`${empCards.FirstName} ${empCards.LastName}`}
    onChange={(e) => {
      const fullName = e.target.value;
      const [firstName, ...lastNameParts] = fullName.split(' ');
      const lastName = lastNameParts.join(' ');

      setEmpCards((prevState) => ({
        ...prevState,
        FirstName: firstName || '',
        LastName: lastName || '',
      }));
    }}
    onBlur={() => {
      // Потенциально можно не изменять логику onBlur, она уже работает как нужно
    }}
  />
</div>



                                <div className="col-xl-6 mb-3 hideInputEmail">
                                  <div className="display-flex" >
                                    <label htmlFor="exampleFormControlInput3" className="form-label">Email <span className="text-danger">*</span></label>
                                    <a className="btn btn-primary btn-sm" onClick={handleButtonClick}>neni</a>
                                    </div>
                                    <input
        type="email"
        name="email"
        className="form-control"
        id="exampleFormControlInput3"
        required
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
                                </div> 
                               
                                <div className="col-xl-6 mb-3 DFlex">
                                  {/* <div style={{ width: '65%', position: 'relative' }}>
                                    <label className="form-label">Národnost <span className="text-danger">*</span></label>
                                   <div ref={selectRef} className="default-select default-select2 form-control" style={{ paddingLeft: '15px' }}>
            <div className="select-selected" onClick={() => setIsOpen(!isOpen)}>
                {selectedValue.label} {selectedValue.icon && <img className="icon" src={selectedValue.icon} alt="icon" />}
                <img src="/arrow-bottom-white.png" style={{ width: '20px' }}></img>
            </div>
            {isOpen && (
                <div className="select-items">
                    <div className="select-items_div" onClick={() => handleSelect('CZ', 'CZ', '/cz.jpg')}>
                        <p>CZ</p> <img className="icon" src="/cz.jpg" alt="CZ icon" />
                    </div>
                    <div className="select-items_div" onClick={() => handleSelect('UA', 'UA', '/ua.jpg')}><p>UA</p> <img className="icon" src="/ua.jpg" alt="CZ icon" /></div>
                    <div className="select-items_div" onClick={() => handleSelect('PL', 'PL', '/pl.webp')}><p>PL</p> <img className="icon" src="/pl.webp" alt="CZ icon" /></div>
                    <div className="select-items_div" onClick={() => handleSelect('LT', 'LT', '/lt.jpg')}><p>LT</p> <img className="icon" src="/lt.jpg" alt="CZ icon" /></div>
                    <div className="select-items_div" onClick={() => handleSelect('LV', 'LV', '/lv.jpg')}><p>LV</p> <img className="icon" src="/lv.jpg" alt="CZ icon" /></div>
                    <div className="select-items_div" onClick={() => handleSelect('RO', 'RO', '/ro.jpg')}><p>RO</p> <img className="icon" src="/ro.jpg" alt="CZ icon" /></div>
                    <div className="select-items_div" onClick={() => handleSelect('MD', 'MD', '/md.jpg')}><p>MD</p> <img className="icon" src="/md.jpg" alt="CZ icon" /></div>
                    <div className="select-items_div" onClick={() => handleSelect('HU', 'HU', '/hu.jpg')}><p>HU</p> <img className="icon" src="/hu.jpg" alt="CZ icon" /></div>
                    <div className="select-items_div" onClick={() => handleSelect('AZ', 'AZ', '/az.jpg')}><p>AZ</p> <img className="icon" src="/az.jpg" alt="CZ icon" /></div>
                    <div className="select-items_div" onClick={() => handleSelect('SK', 'SK', '/sk.jpg')}><p>SK</p> <img className="icon" src="/sk.jpg" alt="CZ icon" /></div>
                    <div className="select-items_div" onClick={() => handleSelect('BG', 'BG', '/bgr.jpg')}><p>BG</p> <img className="icon" src="/bgr.jpg" alt="CZ icon" /></div>
                </div>
            )}
            
        </div>

                                    
                                    </div> */}



<div style={{ width: '65%', position: 'relative' }}>
      <label className="form-label">Národnost <span className="text-danger">*</span></label>
      <div ref={selectRef} className="default-select default-select2 form-control" style={{ paddingLeft: '15px' }}>
        <div className="select-selected" onClick={() => setIsOpen(!isOpen)}>
          {selectedValue.label} {selectedValue.icon && <img className="icon" src={selectedValue.icon} alt="icon" />}
          <img src="/arrow-bottom-white.png" style={{ width: '20px' }} />
        </div>
        {isOpen && (
          <div className="select-items">
            <div ref={selectCZRef} className="select-items_div" onClick={() => handleSelect('CZ', 'CZ', '/cz.jpg')}>
              <p>CZ</p> <img className="icon" src="/cz.jpg" alt="CZ icon" />
            </div>
            <div ref={selectUARef} className="select-items_div" onClick={() => handleSelect('UA', 'UA', '/ua.jpg')}>
              <p>UA</p> <img className="icon" src="/ua.jpg" alt="UA icon" />
            </div>
            <div ref={selectPLRef} className="select-items_div" onClick={() => handleSelect('PL', 'PL', '/pl.webp')}>
              <p>PL</p> <img className="icon" src="/pl.webp" alt="PL icon" />
            </div>
            <div ref={selectLTRef} className="select-items_div" onClick={() => handleSelect('LT', 'LT', '/lt.jpg')}>
              <p>LT</p> <img className="icon" src="/lt.jpg" alt="LT icon" />
            </div>
            <div ref={selectLVRef} className="select-items_div" onClick={() => handleSelect('LV', 'LV', '/lv.jpg')}>
              <p>LV</p> <img className="icon" src="/lv.jpg" alt="LV icon" />
            </div>
            <div ref={selectRORef} className="select-items_div" onClick={() => handleSelect('RO', 'RO', '/ro.jpg')}>
              <p>RO</p> <img className="icon" src="/ro.jpg" alt="RO icon" />
            </div>
            <div ref={selectMDRef} className="select-items_div" onClick={() => handleSelect('MD', 'MD', '/md.jpg')}>
              <p>MD</p> <img className="icon" src="/md.jpg" alt="MD icon" />
            </div>
            <div ref={selectHURef} className="select-items_div" onClick={() => handleSelect('HU', 'HU', '/hu.jpg')}>
              <p>HU</p> <img className="icon" src="/hu.jpg" alt="HU icon" />
            </div>
            <div ref={selectAZRef} className="select-items_div" onClick={() => handleSelect('AZ', 'AZ', '/az.jpg')}>
              <p>AZ</p> <img className="icon" src="/az.jpg" alt="AZ icon" />
            </div>
            <div ref={selectSKRef} className="select-items_div" onClick={() => handleSelect('SK', 'SK', '/sk.jpg')}>
              <p>SK</p> <img className="icon" src="/sk.jpg" alt="SK icon" />
            </div>
            <div ref={selectBGRef} className="select-items_div" onClick={() => handleSelect('BG', 'BG', '/bgr.jpg')}>
              <p>BG</p> <img className="icon" src="/bgr.jpg" alt="BG icon" />
            </div>
          </div>
        )}
      </div>
    </div>





                                    <div style={{width: '30%'}}>
                                    <label className="form-label">Pohlaví <span className="text-danger">*</span></label>

                                    {/* <div className='DFlex' style={{height: '2.813rem'}}>
    <div
      className={`gender-square gender-squareM  ${selectedGender === "Mužský" ? "selected" : ""}`}
      onClick={() => handleGenderChange("Mužský")} 
    >
      M 
    </div>

    <div
      className={`gender-square gender-squareZ ${selectedGender === "Ženský" ? "selected" : ""}`}
      onClick={() => handleGenderChange("Ženský")}
    >
      Ž
    </div>
  </div> */}

<div className='DFlex' style={{height: '2.813rem'}}>
      <div
        ref={manRef} // Привязываем реф для Mužský
        className={`gender-square man gender-squareM ${empCards.Sex === "Mužský" ? "selected" : ""}`}
        onClick={() => handleGenderChange("Mužský")}
      >
        M
      </div>

      <div
        ref={womanRef} // Привязываем реф для Ženský
        className={`gender-square woman gender-squareZ ${empCards.Sex === "Ženský" ? "selected" : ""}`}
        onClick={() => handleGenderChange("Ženský")}
      >
        Ž
      </div>
    </div>
                                    

                                    </div>
                                </div>
                                <div className="col-xl-6 mb-3">
                                    <label htmlFor="exampleFormControlInput88" className="form-label">Telefon <span className="text-danger">*</span></label>
                                    <input name="mobile" type="number" className="form-control" id="exampleFormControlInput88" required placeholder="" />
                                </div>

                                <div className="col-xl-6 mb-3">
                                    <label htmlFor="exampleFormControlInput99" className="form-label">Datum registrace<span className="text-danger">*</span></label>                                    
                                    <input
      type="date"
      id="registr"
      name="registr"
      className="inputReg form-control"
      value={currentDate}
      onChange={(e) => setCurrentDate(e.target.value)}
    />
                                </div>
                                <div className="col-xl-6 mb-3">
                                    <label htmlFor="exampleFormControlInput8" className="form-label">Datum narození <span className="text-danger">*</span></label>                                    
                                    <input
                                      name="birth"
                                      type="text"
                                      className="form-control"
                                      id="exampleFormControlInput88"
                                      required
                                      value={empCards.birth} // Привязка к ключу "birth"
                                      onChange={handleInputChange} // Универсальный обработчик
                                   />
                                </div>
                                

                                <div className="col-xl-6 mb-3 DFlex ubytovnaP">

                                <div style={{ width: '65%' }}>
    <label className="form-label">Ubytovna</label>
    <div className="default-select form-control ubytovnaInputDiv" style={{ position: 'relative' }}>
        <div
            className="selected-option"
            onClick={() => setShowOptions(!showOptions)}
        >
            {selectedSelect || "-"}
            <img src="/arrow-bottom-blue.png" style={{ width: '20px' }} alt="arrow"></img>
        </div>
        {showOptions && (
            <div className="options">
                {hostelsData.map((hostel, index) => (
                    <div
                        key={index}
                        className="option"
                        onClick={() => {
                            handleUbytovnaSelect(hostel.id);
                            setShowOptions(false);
                        }}
                    >
                       <span className='optionSpan'>{hostel.name}</span>
                    </div>
                ))}
                <div
                    className="option"
                    onClick={() => {
                      handleUbytovnaSelect('-'); 
                      setShowOptions(false);
                    }}
                >
                    -
                </div>
            </div>
        )}
    </div>
</div>



<div style={{ width: '30%' }}>
    <label className="form-label">Pokoj</label>
    <div className="default-select form-control pokojInputDiv" style={{ position: 'relative' }}>
        
        <div
            className="selected-option"
            onClick={() => setShowOptionsPokoj(!showOptions)}
        >
            {selectedPokojName !== null ? `${selectedPokojName}` : "-"}
            <img src="/arrow-bottom-blue.png" style={{ width: '20px' }}></img>
        </div>
        {showOptionsPokoj && (
            <div className="options">
                {Rooms.map((room, index) => (
                    <div
                        key={index}
                        className="option"
                        onClick={() => {
                            handlePokojSelect(room.id);
                            handlePokojSelectName(room.name);
                            setShowOptionsPokoj(false);
                        }}
                    >
                        {room.name}
                    </div>
                ))}
                <div
                    className="option"
                    onClick={() => {
                        handlePokojSelect(null); // or whatever value you want to represent the dash
                        setShowOptionsPokoj(false);
                    }}
                >
                    -
                </div>
            </div>
        )}
    </div>
</div>


</div>



<div className="col-xl-6 mb-3 strediskoForm">
      <label className="form-label">Pracovní středisko</label>
      <div className="default-select form-control strediskoInputDiv">
        <div 
          className={`selected-option ${isOpenTovarna ? 'placeholder' : ''}`} 
          onClick={() => setIsOpenTovarna(!isOpenTovarna)}
        >
          {selectedTovarnaId ? selectedTovarna : 'Nepracuje'}
          <img src="/arrow-bottom-blue.png" style={{ width: '20px' }}></img>
        </div>
        {isOpenTovarna && (
          <div className="options">
            <div 
              className={`option ${selectedTovarnaId ? '' : 'selected'}`} 
              onClick={() => handleTovarnaSelect({ id: '', name: '-' })}
            >
              Nepracuje
            </div>
            {tovarnyData.map((tovarna, index) => (
              <div 
                key={index} 
                className={`option ${selectedTovarnaId === tovarna.id ? 'selected' : ''}`} 
                onClick={() => handleTovarnaSelect(tovarna)}
              >
                {tovarna.name}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>

      <div className="mb-3">
                                    <label htmlFor="exampleFormControlInput88" className="form-label">Poznámky</label>
                                    <input name="note" type="text" className="form-control" id="exampleFormControlInput88" placeholder="" />
                                </div>

                                <div className="col-xl-6 mb-3">
                                    <label htmlFor="exampleFormControlInput88" className="form-label">Číslo dokladu</label>
                                    <input name="passId" type="text" className="form-control" id="exampleFormControlInput88"  placeholder="" 
                                    value={empCards.passID}
                                    onChange={handleInputChange} 
                                    />
                                </div>

                                <div className="col-xl-6 mb-3">
                                    <label htmlFor="exampleFormControlInput88" className="form-label">Platnost dokladu</label>
                                    <input name="expirace" type="text" className="form-control" id="exampleFormControlInput88" 
                                     value={empCards.Valabilitate}
                                     onChange={handleInputChange} 
                                    />
                                </div>

                            </div>

                            <div style={{marginTop: '30px'}}>
                              <img style={{width: '100%'}} src="/Addworker.webp"></img>
                            </div>

                            <div style={{marginTop: '30px', marginBottom: '50px'}}>
                                <button type="submit" className="btn btn-primary me-1">Přidat</button>
                                <Link to={"#"} onClick={()=>setAddEmploye(false)} className="btn btn-danger light ms-1">Zrušit</Link>
                            </div>
                        </form>
                    </div>
				</div>
			</Offcanvas>     
        </>
    );
});

export default EmployeeOffcanvas;